<template>
  <v-navigation-drawer
    v-model="visible"
    fixed
    app
    dark
    class="primary"
  >
    <v-toolbar flat class="ap-img-toolbar">
      <v-list class="pa-0">
        <v-list-tile>
          <v-list-tile-action>
            <img src="/images/LogoSmall.png">

          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <TheLeftSidebarAppList />

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'TheLeftSidebar',
  props: {
    visible: Boolean,
  },
  components: {
    TheLeftSidebarAppList: () => import('@/components/TheLeftSidebarAppList'),
  },
};
</script>
